<!-- 考生科目等级统计表 -->
<template>
    <div class="examineeSubject">
        <!-- 查询表单 -->
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="120px">
            <el-form-item label="考试日期">
                <el-date-picker v-model="form.testDate" type="daterange"
                range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="机构名称">
                <el-select v-model="form.mechanism" filterable :clearable="true" placeholder="请输入并选择机构名称">
                    <el-option
                      v-for="item in form.mechanismChoice"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="科目名称">
                <el-select v-model="form.subject" placeholder="请选择科目名称" :clearable="true">
                    <el-option
                      v-for="item in form.subjectChoice"
                      :key="item.id"
                      :label="item.course_name"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="检索">
                <el-select v-model="form.search" placeholder="请选择科目名称" :clearable="true">
                    <el-option
                      v-for="item in form.searchChoice"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="考试大类">
                <el-select v-model="form.bigCalss" filterable :clearable="true" placeholder="请输入并选择考试大类">
                    <el-option
                      v-for="item in form.bigCalssChose"
                      :key="item.id"
                      :label="item.exam_name"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="等级">
                <el-select v-model="form.grade" placeholder="请选择等级" :clearable="true">
                    <el-option
                      v-for="item in form.gradeChoce"
                      :key="item.id"
                      :label="item.level"
                      :value="item.level">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="单据状态">
                <el-select v-model="form.condition" placeholder="请选择单据状态" :clearable="true">
                    <el-option v-for="item in form.conditionChoose" :key="item.id" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="分组码A">
                <el-input v-model="form.usesearch" placeholder="请输入分组码" ></el-input>
            </el-form-item>
        </el-form>
        <!-- 按钮 -->
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="">
                <!-- <button class="print">打印预览</button> -->
                <button class="btn" type="button" v-for="(item,index) in tabBtn" :key="index"
                 @click="toTab(index)" :class="tab==index?'active':''">
                    {{item}}
                </button>
                <button class="derive" type="button"   @click="derive">导出到Excel</button>
            </div>
            <div class="" style="margin-right: 15px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="Reset">
                    重置
                </el-button>
            </div>
        </div>
        <!-- 表1,表2 按钮 -->
        <div class="" v-if="tab==0" >
            <button class="btn_1" type="button" v-for="(item,index) in tabTwo" :key="index"
             @click="toTab_1(index)" :class="tab_1==index?'active':''">
                {{item}}
            </button>
            
        </div>
        <!-- <div class="" v-if="tab_1==1"  style="display: inline-block;">
            <button class="derive" type="button">导出到Excel</button>
        </div> -->
        <!-- 统计表 -->
        <div class="" v-if="tab==0" >
            <!-- 表1 -->
                <el-table :data="censusData_1" border stripe 
                style="width:97.9%;flex: 1;" height="650" v-if="tab_1==0" :key="0"  @sort-change="abc">
                    <el-table-column type="index" label="序号" align="center" width="80">
                    </el-table-column>
                    <el-table-column prop="course_name" sortable="custom"  label="科目名称" align="center" width="150">
                    </el-table-column>
                    <el-table-column prop="level" sortable="custom"  label="等级码" align="center" width="100">
                    </el-table-column>
                    <el-table-column prop="start_time" sortable="custom"  label="开始日期" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="end_time" sortable="custom" label="截止日期" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="exam_num" sortable="custom"  label="应考人次" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="people" sortable="custom"  label="人数比例(%)" align="center" width="150">
                    </el-table-column>
                    <el-table-column prop="no_certificates" sortable="custom"  label="无副证人次" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="certificates" sortable="custom"  label="有副证人次" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="price1" sortable="custom"  label="作品费" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="price2" sortable="custom"  label="副证费" align="center" width="120">
                    </el-table-column>
                    <el-table-column prop="price3" sortable="custom"  label="考试报名费" align="center" width="130">
                    </el-table-column>
                    <el-table-column prop="price4" sortable="custom"  label="收入金额" align="center" width="130">
                    </el-table-column>
                    <el-table-column prop="price5" sortable="custom"  label="收入比例(%)" align="center" width="180">
                    </el-table-column>
                    <el-table-column  label="操作" align="center" fixed="right" width="120">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" size="mini" style="background: #18BC9C;color:#FFFFFF;"
                                @click="detail1(scope.$index, scope.row)">
                                明细表
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                
            <!-- 表2 -->
            <el-table :data="censusData_2" border stripe style="width:97.9%;flex: 1;" height="650" 
            v-if="tab_1==1" :key="1"  @sort-change="abc">
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="org_name" sortable="custom"  label="机构名称" align="center" width="150">
                </el-table-column>
                <el-table-column prop="course_name" sortable="custom"  label="科目名称" align="center" width="120">
                </el-table-column>
                <el-table-column prop="level" sortable="custom"  label="等级码" align="center" width="100">
                </el-table-column>
                <el-table-column prop="start_time" sortable="custom"  label="开始日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="end_time" sortable="custom"  label="截止日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="exam_num" sortable="custom"  label="应考人次" align="center" width="120">
                </el-table-column>
                <el-table-column prop="people" sortable="custom"  label="人数比例(%)" align="center" width="150">
                </el-table-column>
                <el-table-column prop="no_certificates" sortable="custom"  label="无副证人次" align="center" width="120">
                </el-table-column>
                <el-table-column prop="certificates" sortable="custom"  label="有副证人次" align="center" width="120">
                </el-table-column>
                <el-table-column prop="price1" sortable="custom"  label="作品费" align="center" width="100">
                </el-table-column>
                <el-table-column prop="price2" sortable="custom"  label="副证费" align="center" width="100">
                </el-table-column>
                <el-table-column prop="price3" sortable="custom"  label="考试报名费" align="center" width="130">
                </el-table-column>
                <el-table-column prop="price4" sortable="custom"  label="收入金额" align="center" width="130">
                </el-table-column>
                <el-table-column prop="price5" sortable="custom"  label="收入比例(%)" align="center" width="180">
                </el-table-column>
                <el-table-column  label="操作" align="center" fixed="right" width="120">
                    <template slot-scope="scope">
                        <el-button icon="el-icon-edit" size="mini" style="background: #18BC9C;color:#FFFFFF;"
                            @click="detail2(scope.$index, scope.row)">
                            明细表
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>       
        </div>
        <!-- 考试明细表 -->
        <div class="" v-if="tab==1" :key="3">
            <el-table :data="testDetailed" border stripe style="width:97.9%;flex: 1;" height="650"  @sort-change="abc">
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="certificate_no" sortable="custom"  label="准考证" align="center" width="200">
                </el-table-column>
                <el-table-column prop="username" sortable="custom"  label="学员姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="pinyin_name" sortable="custom"  label="拼音名" align="center" width="150">
                </el-table-column>
                <el-table-column prop="idcard" sortable="custom"  label="身份证号" align="center" width="200">
                </el-table-column>
                <el-table-column prop="gender" sortable="custom"  label="性别" align="center" width="80">
                </el-table-column>
                <el-table-column prop="nation2" sortable="custom"  label="国籍" align="center" width="100">
                </el-table-column>
                <el-table-column prop="nation" sortable="custom"  label="民族" align="center" width="100">
                </el-table-column>
                <el-table-column prop="birthdate" sortable="custom"  label="出生日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="course_name" label="学科" sortable="custom"  align="center" width="120">
                </el-table-column>
                <el-table-column prop="exam_level" sortable="custom"  label="等级码" align="center" width="120">
                </el-table-column>
                <el-table-column prop="original_level" sortable="custom"  label="等级" align="center" width="120">
                </el-table-column>
                <el-table-column prop="enroll_price" sortable="custom" label="考试费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="remark" sortable="custom"  label="说明" align="center" width="200">
                </el-table-column>
                <el-table-column prop="enroll_number" sortable="custom"  label="安排单号" align="center" width="150">
                </el-table-column>
                <el-table-column prop="org_name" sortable="custom"  label="提供机构" align="center" width="150">
                </el-table-column>
                <el-table-column prop="zjm" sortable="custom"  label="助记码" align="center" width="120">
                </el-table-column>
                <el-table-column prop="is_deputy" sortable="custom"  label="是否副证" align="center" width="120">
                </el-table-column>
            </el-table>       
        </div>
        <!-- 国美格式报名表 -->
        <div class="" v-if="tab==2" >
            <el-table :data="gomeData" border stripe style="width:97.9%;flex: 1;"  height="650"  @sort-change="abc">
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="agroup" sortable="custom"  label="分组码" align="center" width="100">
                </el-table-column>
                <el-table-column prop="username" sortable="custom"   label="姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="pinyin_name" sortable="custom"  label="拼音名" align="center" width="150">
                </el-table-column>
                <el-table-column prop="idcard" sortable="custom" label="身份证号码" align="center" width="180">
                </el-table-column>
                <el-table-column prop="idcard2" sortable="custom"  label="护照号码" align="center" width="150">
                </el-table-column>
                <el-table-column prop="mobile1" sortable="custom"   label="手机号码" align="center" width="120">
                </el-table-column>
                <el-table-column prop="gender" sortable="custom"  label="性别" align="center" width="80">
                </el-table-column>
                <el-table-column prop="birthdate" sortable="custom"  label="出生日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="course_name" sortable="custom"  label="专业" align="center" width="120">
                </el-table-column>
                <el-table-column prop="exam_level" sortable="custom"  label="级别" align="center" width="80">
                </el-table-column>
                <el-table-column prop="nation2" sortable="custom"  label="国籍" align="center" width="100">
                </el-table-column>
                <el-table-column prop="nation" sortable="custom"  label="民族" align="center" width="80">
                </el-table-column>
                <el-table-column prop="original_level" sortable="custom"  label="原级别" align="center" width="100">
                </el-table-column>
                <el-table-column prop="is_deputy" sortable="custom"  label="是否副证" align="center" width="120">
                </el-table-column>
                <el-table-column prop="org_name" sortable="custom"  label="所属机构" align="center" width="150">
                </el-table-column>
                <el-table-column prop="enroll_number" sortable="custom"  label="安排单号" align="center" width="150">
                </el-table-column>
                <el-table-column prop="exam_date" sortable="custom"  label="考试日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="starttime" sortable="custom"  label="开始时间" align="center" width="120">
                </el-table-column>
                <el-table-column prop="endtime" sortable="custom"  label="结束时间" align="center" width="120">
                </el-table-column>
                <el-table-column prop="remark" sortable="custom"  label="考场摘要" align="center" width="400">
                </el-table-column>
            </el-table>       
        </div>
        <!-- 底部 -->
        <div class="" style=" display: flex; justify-content: space-between;">
            <!-- 统计表显示 -->
            <div class="" style="margin-left: 22px;width: 1000px;" v-if="tab==0" >
                <!-- 表1显示 -->
                <div class="" v-if="tab_1==0" >
                    <div class="txtbox">
                        记录数：<input type="text" class="itp" :value="form_1.recordNum" disabled="disabled"/>
                    </div>
                    <div class="txtbox">
                        应考人次：<input type="text" class="itp" :value="form_1.personTime" disabled="disabled"/>
                    </div>
                    <div class="txtbox">
                        无副证人次：<input type="text" class="itp" :value="form_1.nothing" disabled="disabled" />
                    </div>
                    <div class="txtbox">
                        有副证人次：<input type="text" class="itp" :value="form_1.possess" disabled="disabled"/>
                    </div>
                    <div class="txtbox">
                        报名费金额：<input type="text" class="itp" :value="form_1.registrationFee" disabled="disabled"/>
                    </div>
                    <div class="txtbox">
                        收入金额汇总：<input type="text" class="itp" :value="form_1.amount" disabled/>
                    </div>
                </div>
                <!-- 表2显示 -->
                <div class="" v-if="tab_1==1" >
                    <div class="txtbox">
                        记录数：<input type="text" class="itp" :value="form_2.recordNum" disabled="disabled"/>
                    </div>
                    <div class="txtbox">
                        应考人次：<input type="text" class="itp" :value="form_2.personTime" disabled="disabled"/>
                    </div>
                    <div class="txtbox">
                        无副证人次：<input type="text" class="itp" :value="form_2.nothing" disabled="disabled" />
                    </div>
                    <div class="txtbox">
                        有副证人次：<input type="text" class="itp" :value="form_2.possess" disabled="disabled"/>
                    </div>
                    <div class="txtbox">
                        报名费金额：<input type="text" class="itp" :value="form_2.registrationFee" disabled="disabled"/>
                    </div>
                    <div class="txtbox">
                        收入金额汇总：<input type="text" class="itp" :value="form_2.amount" disabled="disabled"/>
                    </div>
                </div>
            </div>
            <!-- 考生明细表显示 -->
            <div class="" style="margin-left: 22px;" v-if="tab==1" >
                <div class="txtbox">
                    无副证考生数：<input type="text" class="itp" :value="form_3.nothing" disabled="disabled" />
                </div>
                <div class="txtbox">
                    有副证考生数：<input type="text" class="itp" :value="form_3.possess" disabled="disabled"/>
                </div>
            </div>
            <!-- 国美格式显示 -->
            <div class="" style="margin-left: 22px;" v-if="tab==2" >
                <div class="txtbox">
                    无副证考生数：<input type="text" class="itp" :value="form_4.nothing" disabled="disabled" />
                </div>
                <div class="txtbox">
                    有副证考生数：<input type="text" class="itp" :value="form_4.possess" disabled="disabled"/>
                </div>
            </div>
            <!--分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[20,100,500,2000,5000,50000]" :page-size="10" background
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    testDate:"",//考试日期
                    mechanism:"",// 机构名称
                    mechanismChoice:[],// 机构名称选项
                    subject:"",//科目名称
                    subjectChoice:[],//科目名称选项
                    search:0,//检索
                    searchChoice:[
                        {value:0,label:"无"},
                        {value:1,label:"未安排考生"}
                    ],//检索选项
                    bigCalss:'',//考试大类
                    bigCalssChose:[],
                    grade:'',
                    gradeChoce:[],//等级选择
                    condition: 0, //单据状态
                    conditionChoose: [{
                            value: 0,
                            label: "全部"
                        },
                        {
                            value: 1,
                            label: "草稿"
                        },
                        {
                            value: 2,
                            label: "报考"
                        },
                        {
                            value: 3,
                            label: "准考"
                        },
                        {
                            value: 4,
                            label: "考试OK"
                        },
                        {
                            value: 5,
                            label: "取消"
                        },
                        {
                            value: 6,
                            label: "退款取消"
                        },
                        {
                            value: 7,
                            label: "延期"
                        },
                        {
                            value: 8,
                            label: "缺考"
                        }
                    ], //单据状态选择
                    usesearch:"",//搜索
                },
                tabBtn:["统计表","考生明细表","国美格式报名表"],
                tab:0,//代表下标
                tabTwo:["科目统计","机构统计"],
                tab_1:0,
                censusData_1:[],//统计表 表1
                censusData_2:[],//统计表 表2
                testDetailed:[],//考试明细表
                gomeData:[],//国美格式报名表
                currentPage: 1,
                currentLimit: 20,
                total: 0,
                form_1:{//统计表表1底部显示
                   recordNum:"",//记录数 
                   personTime:"",//应考人次
                   nothing:"",//无副证人次
                   possess:"",//有副证人次
                   registrationFee:"",//报名费金额
                   amount:""//收入金额汇总
                },
                form_2:{//统计表表2底部显示
                   recordNum:"",//记录数 
                   personTime:"",//应考人次
                   nothing:"",//无副证人次
                   possess:"",//有副证人次
                   registrationFee:"",//报名费金额
                   amount:""//收入金额汇总
                },
                form_3:{//考生明细表底部显示
                    nothing:"",//无副证考生数
                    possess:"",//有副证考生数
                },
                form_4:{//国美格式报名表底部显示
                    visual:1,//副证视角
                    nothing:"",//无副证考生数
                    possess:"",//有副证考生数
                },
                arr:[]
            }
        },
        activated() {
            this.getList()
        },
        created() {
             //机构名称
             this.$request({
                 url: "/api/mechanism/list",
                 method: "POST",
                 data: {
                     page: 1,
                     limit: 1000
                 }
             }).then(res => {
                 // console.log("选择机构",res.data.list)
                 this.form.mechanismChoice = res.data.list
             })
             
            // 科目数据
            this.$request({
                url: "/api/course/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 100
                }
            }).then(res => {
                // console.log("选择机构",res.data.list)
                this.form.subjectChoice = res.data.list
            })
            
            // 考试大类
            this.$request({
                url: '/api/ExamCategory/list',
                method: 'POST',
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log(res,"考试大类")
                if (res.code == 1) {
                    this.form.bigCalssChose = res.data.list
                }
            })
            //等级选择
            this.$request({
                url: "/api/level/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log("选择机构",res.data.list)
                this.form.gradeChoce = res.data.list
            })
            this.getList()
        },
        methods:{
            // 列表
            getList(){
                if(this.tab==0){//统计表
                    if(this.tab_1==0){//表1
                        this.$request({
                            url:'/api/Statistics/courses',
                            method:"POST",
                            data:{
                               page:this.currentPage,
                               limit:this.currentLimit,
                               exam_date:this.form.testDate,//考试日期
                               org_id:this.form.mechanism,//机构id
                               course_id:this.form.subject,//科目id
                               type:this.form.search,
                               sort:this.arr,
                               apply_category:this.form.bigCalss,//考试大类
                               exam_level:this.form.grade,
                               status:this.form.condition,
                               agroup:this.form.usesearch
                            }
                        }).then(res=>{
                            if(res.code==1){
                                // console.log(res,"统计表-表1")
                                this.censusData_1=res.data.list
                                this.total=res.data.total
                                
                                this.form_1.recordNum=res.data.total//记录数
                                this.form_1.personTime=res.data.exam_num//应考人次
                                this.form_1.nothing=res.data.no_certificates//无副证人次
                                this.form_1.possess=res.data.certificates//有副证人次
                                this.form_1.registrationFee=res.data.price3//报名费金额
                                this.form_1.amount=res.data.price4//收入金额汇总
                            }
                        })
                    }else if(this.tab_1==1){//表2
                        this.$request({
                            url:'/api/Statistics/mcourses',
                            method:"POST",
                            data:{
                               page:this.currentPage,
                               limit:this.currentLimit,
                               exam_date:this.form.testDate,//考试日期
                               org_id:this.form.mechanism,//机构id
                               course_id:this.form.subject,//科目id
                               type:this.form.search,
                               sort:this.arr,
                               apply_category:this.form.bigCalss,
                               exam_level:this.form.grade,
                               status:this.form.condition,
                               agroup:this.form.usesearch
                            }
                        }).then(res=>{
                            if(res.code==1){
                                console.log(res,"统计表-表2")
                                this.censusData_2=res.data.list
                                this.total=res.data.total
                                
                                this.form_2.recordNum=res.data.total//记录数
                                this.form_2.personTime=res.data.exam_num//应考人次
                                this.form_2.nothing=res.data.no_certificates//无副证人次
                                this.form_2.possess=res.data.certificates//有副证人次
                                this.form_2.registrationFee=res.data.price3//报名费金额
                                this.form_2.amount=res.data.price4//收入金额汇总
                            }
                        })
                    }
                }else if(this.tab==1){//考试明细表
                    this.$request({
                        url:'/api/Statistics/student_stics',
                        method:"POST",
                        data:{
                            page:this.currentPage,
                            limit:this.currentLimit,
                            exam_date:this.form.testDate,//考试日期
                            org_id:this.form.mechanism,//机构id
                            course_id:this.form.subject,//科目id
                            type:this.form.search,
                            sort:this.arr,
                            apply_category:this.form.bigCalss,
                            exam_level:this.form.grade,
                            status:this.form.condition,
                            agroup:this.form.usesearch
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res,"考试明细表")
                            this.testDetailed=res.data.list
                            this.total=res.data.total
                            this.form_3.nothing=res.data.no_certificates//无副证考生数
                            this.form_3.possess=res.data.certificates//有副证考生数
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }else if(this.tab==2){//国美格式报名表
                    this.$request({
                        url:'/api/Statistics/gm_stics',
                        method:"POST",
                        data:{
                            page:this.currentPage,
                            limit:this.currentLimit,
                            exam_date:this.form.testDate,//考试日期
                            org_id:this.form.mechanism,//机构id
                            course_id:this.form.subject,//科目id
                            type:this.form.search ,
                            sort:this.arr,
                            apply_category:this.form.bigCalss,
                            exam_level:this.form.grade,
                            status:this.form.condition,
                            agroup:this.form.usesearch
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res,'国美格式报名表')
                            this.gomeData=res.data.list
                            this.total=res.data.total
                            this.form_4.nothing=res.data.no_certificates//无副证考生数
                            this.form_4.possess=res.data.certificates//有副证考生数
                        }
                    })
                }
            },
            toTab(index){//赋值下标
                this.tab=index
                this.arr=[]
                this.getList()
            },
            toTab_1(index){
                this.tab_1=index
                this.arr=[]
                this.getList()
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            query() { //查询
                // let text = this.form.usesearch.replace(/[^\w\.\/]/ig,'')
                   if (/[\u4E00-\u9FA5]/g.test(this.form.usesearch)) {
                      return  this.open4()
                    } 
                this.currentPage = 1
                this.getList()
            },
            Reset() { //重置
                this.form.testDate=""//考试日期
                this.form.mechanism=""// 机构名称
                this.form.subject=""//科目名称
                this.form.search=0
                this.arr=[]
                this.form.bigCalss=''
                this.form.usesearch=""
                this.form.condition=0
                this.getList()
            },
            open4(){
                     this.$message.error('分组码 请输入字母和数字');
            },
            // 导出
            derive(){
                if(this.tab==0){//统计表
                    if(this.tab_1==0){
                        // console.log('表1')
                        location.href =
                            `https://artxt.szart.cn/api/public/index.php/api/Statistics/export_course?
                            page=${this.currentPage}&limit=${this.currentLimit}&exam_date=${this.form.testDate}
                            &org_id=${this.form.mechanism}&course_id=${this.form.subject}&type=${this.form.search}
                            &apply_category=${this.form.bigCalss}&exam_level=${this.form.grade}&status=${this.form.condition}&agroup=${this.form.usesearch}`
                             
                    }else if(this.tab_1==1){
                        console.log('表2')
                        location.href =
                            `https://artxt.szart.cn/api/public/index.php/api/Statistics/export_mcourses?
                            page=${this.currentPage}&limit=${this.currentLimit}&exam_date=${this.form.testDate}
                            &org_id=${this.form.mechanism}&course_id=${this.form.subject}&type=${this.form.search}
                            &apply_category=${this.form.bigCalss}&exam_level=${this.form.grade}&status=${this.form.condition}&agroup=${this.form.usesearch}`
                    }
                }else if(this.tab==1){//考生明细表
                    // console.log('考生明细表')
                    location.href =
                        `https://artxt.szart.cn/api/public/index.php/api/Statistics/export_student_stics?
                        page=${this.currentPage}&limit=${this.currentLimit}&exam_date=${this.form.testDate}
                        &org_id=${this.form.mechanism}&course_id=${this.form.subject}&type=${this.form.search}
                        &apply_category=${this.form.bigCalss}&exam_level=${this.form.grade}&status=${this.form.condition}&agroup=${this.form.usesearch}`
                    
                }else if(this.tab==2){//国美格式报名表
                    // console.log('国美格式报名表')
                    location.href = `https://artxt.szart.cn/api/public/index.php/api/Statistics/export_gm?
                    page=${this.currentPage}&limit=${this.currentLimit}&exam_date=${this.form.testDate}
                        &org_id=${this.form.mechanism}&course_id=${this.form.subject}&type=${this.form.search}
                        &apply_category=${this.form.bigCalss}&exam_level=${this.form.grade}&status=${this.form.condition}&agroup=${this.form.usesearch}`
                }
            },
            // 姓名排序
            fn2(a, b){
                return a.username.localeCompare(b.username)
            },
            //排序
            abc(i){ 
                this.censusData_1=[]//统计表 表1
                this.censusData_2=[]//统计表 表2
                this.testDetailed=[]//考试明细表
                this.gomeData=[]//国美格式报名表
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            },
            // 表一明细
            detail1(index,row){
                this.$router.push({
                    path:"detailedOne",
                    query:{
                        course_id:row.course_id,//科目id
                        exam_level:row.exam_level,//等级
                        org_id:row.org_id,//机构id
                        exam_date:this.form.testDate,//考试日期
                        type:this.form.search,//检索
                        apply_category:this.form.bigCalss,//考试大类
                        agroup:this.form.usesearch
                    }
                })
            },
            // 表二明细
            detail2(index,row){
                this.$router.push({
                    path:"detailedTwo",
                    query:{
                        course_id:row.course_id,
                        exam_level:row.exam_level,
                        org_id:row.org_id,
                        exam_date:this.form.testDate,//考试日期
                        type:this.form.search,//检索
                        apply_category:this.form.bigCalss,//考试大类
                        agroup:this.form.usesearch

                    }
                })
            }
        }
    }
</script>

<style scoped="scoped">
    .examineeSubject{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
    }
    
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    .form .el-select{
        width: 276px;
        height: 37px;
    }
    /* 打印预览 */
    .print{
        display: inline-block;
        padding-left: 10px;
        width: 94px;
        height: 30px;
        border-radius: 3px;
        vertical-align: middle;
        background: url(../../assets/print.png)8px 9px no-repeat #18BC9C;
        border: none;
        color: #FFFFFF;
        margin-left: 21px;
    }
    .btn{
       display: inline-block;
       border: 1px solid #999999;
       background-color: #FFFFFF;
       padding: 5px 15px;
       margin-left: 15px;
       font-size: 14px;
       height: 30px;
       vertical-align: middle;
       border-radius: 3px;
       cursor: pointer;
    }
    .btn_1{
       display: inline-block;
       border: 1px solid #999999;
       background-color: #FFFFFF;
       padding: 5px 20px;
       margin-left: 21px;
       margin-top: 17px;
       font-size: 16px;
       border-radius: 3px;
       cursor: pointer;
    }
    /* 选中样式 */
    .active{
        background: #CDEEEB;
        color: #333;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 29px;
    }
    /* 显示值 */
    .txtbox{
        width: 260px;
        height: 19px;
        display: inline-block;
        margin-bottom: 18px;
        margin-right: 5px;
        font-size: 14px;
        color: #000000;
    }
    .itp{
        padding-left: 5px;
        width: 120px;
        border: 1px solid #ccc;
        height: 20px;
        outline: none;
        font-size: 14px;
        background-color: #FFFFFF;
    }
    /* 考生明细表 */
    ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #18BC9C;
        
    }
    ::v-deep .el-radio__label{
        color: #000;
    }
    ::v-deep .el-radio__input.is-checked+.el-radio__label {
        color: #18BC9C;
    }
    
    ::v-deep .el-radio__inner:hover {
        border-color: #18BC9C;
    }
    /* 国美格式报名表 */
    /* 导出按钮 */
    .derive{
        display: inline-block;
        height: 30px;
        border-radius: 3px;
        padding:5px 10px 5px 25px;
        margin-left: 21px;
        margin-top: 17px;
        vertical-align: bottom;
        background: url(../../assets/export.png) 7px 8px no-repeat #FF7300;
        border: none;
        color: #fff;
        cursor: pointer;
    }
    /* 分页 */
    .el-pagination {      
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>